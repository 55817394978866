import './jquery'
import './parsley'
import './flush'
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/modal';
import 'imagesloaded';
import 'bootstrap-select';
import 'jquery-match-height';
import 'cookieconsent';
import 'slick-carousel';

import './vendor/jquery.fancybox';

import './parts/base';
import './parts/header';
import './parts/slick';
import './parts/slick2';

import './parts/filter';

// import { registerScrollEvents, createUpdateAll } from './parts/events';

import '../../node_modules/aos/dist/aos.js';
import AOS from 'aos';


AOS.init({
    // Global settings:
    disable: 'mobile', // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
    initClassName: 'aos-init', // class applied after initialization
    animatedClassName: 'aos-animate', // class applied on animation
    useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
    disableMutationObserver: false, // disables automatic mutations' detections (advanced)
    debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
    throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
    
  
    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    offset: 500, // offset (in px) from the original trigger point
    delay: 0, // values from 0 to 3000, with step 50ms
    duration: 1000, // values from 0 to 3000, with step 50ms
    easing: 'ease', // default easing for AOS animations
    once: false, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
    anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
  
  });
  

  $(document).ready(function () {
    // check if progress-animation is on the page 
    var progressBlock = document.getElementsByClassName('progress-animation');
  
    // get alle progresBars
    var progressBars = document.getElementsByClassName('progress-fill');
  
    // iterator 
  
    if (progressBlock) {
        window.onscroll = function () {
            
            var i = 0;
            //console.log(progressBlock[0].getBoundingClientRect().top);
            //console.log(window.innerHeight);
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;
  
            try {
                for (var _iterator = progressBars[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var progressBar = _step.value;
  
                    if (progressBlock[0].getBoundingClientRect().top <= window.innerHeight - 100) {
                        progressBar.setAttribute("style", "width:" + progressBar.dataset.percent + "%;transition-delay:" + i / 10 + "s");
                    } else {
                        progressBar.setAttribute("style", "width:0%;transition-delay:0s");
                    }
                    i++;
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }
        };
    }
  });